
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import booleanSelect from "@/core/data/booleanselect";
import { AsoProject } from "@/store/modules/ApiModule";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";

export default defineComponent({
    name: "update-aso-report-modal",
    props: {
        asoproject: {
            type: Object as () => AsoProject,
            required: true,
        },
    },
    setup(props) {
        const formRef = ref<null | any>(null);
        const updateAsoReportModalRef = ref<null | HTMLElement>(null);
        const loading = ref<boolean>(false);
        const cancelRequested = ref(false);
        const currentController = ref<AbortController | null>(null);
        let formData = reactive({
            dates: [] as string[],
            countries: "",
            options: [] as string[],
            job: false,
            job_name: "",
        });
        const store = useStore();
        const rules = ref({});

        const isAndroid = computed(() => props.asoproject.os === "GOOGLE PLAY");

        watch(props.asoproject, (newValue) => {
            formData.countries = newValue.countries;
        });

        // Helper function to format time in mm:ss
        const formatTime = (timeInSeconds: number): string => {
            const minutes = Math.floor(timeInSeconds / 60);
            const seconds = Math.floor(timeInSeconds % 60);
            const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
            const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${minutesStr}:${secondsStr}`;
        };

        // Split the date range into monthly periods.
        const splitDateRange = (from: string, to: string) => {
            const periods: { from: string; to: string }[] = [];
            let currentStart = new Date(from);
            const endDate = new Date(to);
            while (currentStart < endDate) {
                let currentEnd = new Date(currentStart);
                currentEnd.setMonth(currentEnd.getMonth() + 1);
                if (currentEnd > endDate) {
                    currentEnd = endDate;
                }
                periods.push({
                    from: currentStart.toISOString().split("T")[0],
                    to: currentEnd.toISOString().split("T")[0],
                });
                currentStart = currentEnd;
            }
            return periods;
        };

        // Generate the HTML for the tasks table.
        const generateTasksTable = (tasks: any[]) => {
            let html = `<table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th style="border: 1px solid #ccc; padding: 5px;">#</th>
          <th style="border: 1px solid #ccc; padding: 5px;">Option</th>
          <th style="border: 1px solid #ccc; padding: 5px;">Country</th>
          <th style="border: 1px solid #ccc; padding: 5px;">Period</th>
          <th style="border: 1px solid #ccc; padding: 5px;">Status</th>
          <th style="border: 1px solid #ccc; padding: 5px;">Details</th>
          <th style="border: 1px solid #ccc; padding: 5px;">Time</th>
        </tr>
      </thead>
      <tbody>`;
            tasks.forEach((task, index) => {
                let details = "";
                if (
                    task.status.toLowerCase() === "done" &&
                    task.result &&
                    task.result.numUpdatedRows !== undefined
                ) {
                    details = `Rows updated: ${task.result.numUpdatedRows}`;
                } else if (
                    task.status.toLowerCase() === "error" &&
                    task.result &&
                    task.result.message
                ) {
                    details = `Error: ${task.result.message}`;
                }
                const timeDisplay =
                    task.timeTaken !== undefined && task.timeTaken !== null
                        ? formatTime(task.timeTaken)
                        : "";
                let rowStyle = "border: 1px solid #ccc; padding: 5px;";
                if (task.status.toLowerCase() === "done") {
                    rowStyle += " background-color: #d4edda;";
                } else if (task.status.toLowerCase() === "error") {
                    rowStyle += " background-color: #f8d7da;";
                } else if (task.status.toLowerCase() === "cancelled") {
                    rowStyle += " background-color: #e2e3e5;";
                }
                // Display group tasks with friendly labels.
                const displayOption =
                    task.option === "apptweak_reviews_group"
                        ? "All apptweak reviews"
                        : task.option === "apptweak_group"
                            ? "All other apptweak options"
                            : task.option;
                html += `<tr style="${rowStyle}">
          <td style="border: 1px solid #ccc; padding: 5px;">${index + 1}</td>
          <td style="border: 1px solid #ccc; padding: 5px;">${displayOption}</td>
          <td style="border: 1px solid #ccc; padding: 5px;">${task.country}</td>
          <td style="border: 1px solid #ccc; padding: 5px;">${task.period.from} to ${task.period.to}</td>
          <td style="border: 1px solid #ccc; padding: 5px;">${task.status.toUpperCase()}</td>
          <td style="border: 1px solid #ccc; padding: 5px;">${details}</td>
          <td style="border: 1px solid #ccc; padding: 5px;">${timeDisplay}</td>
        </tr>`;
            });
            html += `</tbody></table>`;
            return html;
        };

        // Show the tasks modal.
        const showTasksModal = (tasks: any[], processing: boolean) => {
            const html = generateTasksTable(tasks);
            Swal.fire({
                title: "Tasks Progress",
                html,
                width: "1200px",
                heightAuto: false,
                showConfirmButton: !processing,
                confirmButtonText: "Close",
                showCancelButton: processing,
                cancelButtonText: "Cancel",
                allowOutsideClick: false,
                didOpen: () => {
                    const popup = Swal.getPopup();
                    if (popup) {
                        popup.style.height = "800px";
                        popup.style.minHeight = "800px";
                        popup.style.overflowY = "auto";
                    }
                    if (processing) {
                        const cancelBtn = Swal.getCancelButton();
                        if (cancelBtn) {
                            cancelBtn.addEventListener("click", () => {
                                cancelRequested.value = true;
                                if (currentController.value) {
                                    currentController.value.abort();
                                }
                                Swal.close();
                            });
                        }
                    }
                },
            });
        };

        // Update the tasks modal.
        const updateTasksModal = (tasks: any[], processing: boolean) => {
            const html = generateTasksTable(tasks);
            Swal.update({
                title: "Tasks Progress",
                html,
                width: "1200px",
                heightAuto: false,
                showConfirmButton: !processing,
                confirmButtonText: "Close",
                showCancelButton: processing,
                cancelButtonText: "Cancel",
                allowOutsideClick: false,
                didOpen: () => {
                    const popup = Swal.getPopup();
                    if (popup) {
                        popup.style.height = "800px";
                        popup.style.minHeight = "800px";
                        popup.style.overflowY = "auto";
                    }
                },
            });
        };

        // Process tasks sequentially.
        const processTasks = async (tasks: any[]) => {
            for (let i = 0; i < tasks.length; i++) {
                if (cancelRequested.value) {
                    tasks[i].status = "cancelled";
                    updateTasksModal(tasks, false);
                    break;
                }
                tasks[i].status = "processing";
                tasks[i].startTime = Date.now();
                updateTasksModal(tasks, true);
                try {
                    let optionsArray: string[] = [];
                    // For grouped apptweak tasks.
                    if (
                        tasks[i].option === "apptweak_reviews_group" ||
                        tasks[i].option === "apptweak_group"
                    ) {
                        optionsArray = tasks[i].groupOptions.slice();
                        if (formData.options.includes("apptweak_competitors")) {
                            optionsArray.push("apptweak_competitors");
                        }
                    } else {
                        optionsArray = [tasks[i].option];
                        if (
                            tasks[i].option.startsWith("console_") &&
                            formData.options.includes("console_include_ww")
                        ) {
                            optionsArray.push("console_include_ww");
                        }
                    }
                    const payload = {
                        id: props.asoproject.id,
                        from: tasks[i].period.from,
                        to: tasks[i].period.to,
                        countries: tasks[i].country,
                        options: optionsArray,
                        job: formData.job,
                        job_name: formData.job_name,
                    };
                    const controller = new AbortController();
                    currentController.value = controller;
                    const config = { signal: controller.signal } as AxiosRequestConfig & { signal?: AbortSignal };
                    const response = await ApiService.post("asoprojects/report", payload, config);
                    tasks[i].status = "done";
                    tasks[i].result = response.data;
                } catch (error: any) {
                    if (error.name === "AbortError") {
                        tasks[i].status = "cancelled";
                    } else {
                        tasks[i].status = "error";
                        tasks[i].result = error.response ? error.response.data : error;
                    }
                }
                tasks[i].timeTaken = (Date.now() - tasks[i].startTime);
                updateTasksModal(tasks, true);
            }
        };

        // Main submit function.
        const submit = () => {
            if (!formRef.value) return;
            formRef.value.validate(async (valid: boolean) => {
                if (valid) {
                    if (formData.job) {
                        const countries = formData.countries
                            .split(",")
                            .map((c) => c.trim())
                            .filter((c) => c);
                        let baseOptions = formData.options.filter(
                            (opt) =>
                                opt !== "apptweak_competitors" && opt !== "console_include_ww"
                        );
                        if (
                            formData.options.includes("apptweak_competitors") &&
                            baseOptions.some((opt) => opt.startsWith("apptweak_"))
                        ) {
                            baseOptions.push("apptweak_competitors");
                        }
                        if (
                            formData.options.includes("console_include_ww") &&
                            baseOptions.some((opt) => opt.startsWith("console_"))
                        ) {
                            baseOptions.push("console_include_ww");
                        }
                        const payload = {
                            id: props.asoproject.id,
                            from: formData.dates[0],
                            to: formData.dates[1],
                            countries: countries,
                            options: baseOptions,
                            job: formData.job,
                            job_name: formData.job_name,
                        };
                        try {
                            const response = await ApiService.post("asoprojects/report", payload);
                            Swal.fire({
                                text: `${response.data.numUpdatedRows} rows have been updated.`,
                                icon: "success",
                                confirmButtonText: "Close",
                                allowOutsideClick: false,
                            });
                            if (formRef.value) {
                                formRef.value.resetFields();
                            }
                            hideModal(updateAsoReportModalRef.value);
                        } catch (error: any) {
                            Swal.fire({
                                text: error.response.data.message,
                                icon: "error",
                                confirmButtonText: "Close",
                                allowOutsideClick: false,
                            });
                        }
                        return;
                    }
                    const [from, to] = formData.dates;
                    const diffMonths =
                        (new Date(to).getFullYear() - new Date(from).getFullYear()) * 12 +
                        (new Date(to).getMonth() - new Date(from).getMonth());
                    const periods =
                        diffMonths >= 1 ? splitDateRange(from, to) : [{ from, to }];
                    const countries = formData.countries
                        .split(",")
                        .map((c) => c.trim())
                        .filter((c) => c);
                    // Partition options: split apptweak options into reviews and others.
                    const optionsToSplit = formData.options.filter(
                        (opt) =>
                            opt !== "apptweak_competitors" && opt !== "console_include_ww"
                    );
                    const apptweakOptions = optionsToSplit.filter((opt) =>
                        opt.startsWith("apptweak_")
                    );
                    const apptweakReviews = apptweakOptions.filter(
                        (opt) => opt === "apptweak_reviews"
                    );
                    const apptweakOthers = apptweakOptions.filter(
                        (opt) => opt !== "apptweak_reviews"
                    );
                    const otherOptions = optionsToSplit.filter(
                        (opt) => !opt.startsWith("apptweak_")
                    );
                    let tasks: any[] = [];
                    periods.forEach((period) => {
                        countries.forEach((country) => {
                            if (apptweakReviews.length > 0) {
                                tasks.push({
                                    period,
                                    country,
                                    option: "apptweak_reviews_group",
                                    groupOptions: apptweakReviews,
                                    status: "pending",
                                    result: null,
                                    timeTaken: null,
                                });
                            }
                            if (apptweakOthers.length > 0) {
                                tasks.push({
                                    period,
                                    country,
                                    option: "apptweak_group",
                                    groupOptions: apptweakOthers,
                                    status: "pending",
                                    result: null,
                                    timeTaken: null,
                                });
                            }
                            otherOptions.forEach((option) => {
                                tasks.push({
                                    period,
                                    country,
                                    option,
                                    status: "pending",
                                    result: null,
                                    timeTaken: null,
                                });
                            });
                        });
                    });
                    cancelRequested.value = false;
                    showTasksModal(tasks, true);
                    // Start a timer to update elapsed time every second.
                    const timerId = setInterval(() => {
                        tasks.forEach((task) => {
                            if (task.status === "processing" && task.startTime) {
                                task.timeTaken = (Date.now() - task.startTime) / 1000;
                            }
                        });
                        updateTasksModal(tasks, true);
                    }, 1000);
                    await processTasks(tasks);
                    clearInterval(timerId);
                    updateTasksModal(tasks, false);
                    if (formRef.value) {
                        formRef.value.resetFields();
                    }
                    hideModal(updateAsoReportModalRef.value);
                } else {
                    Swal.fire({
                        text: "Sorry, some errors were detected. Please try again.",
                        icon: "error",
                        confirmButtonText: "Ok, got it!",
                        allowOutsideClick: false,
                    });
                    return false;
                }
            });
        };

        return {
            isAndroid,
            formData,
            rules,
            submit,
            booleanSelect,
            formRef,
            loading,
            updateAsoReportModalRef,
        };
    },
});
